<template>
  <div class="w-full">
    <BaseLabel
      :hide-asterisk="hideAsterisk"
      :label="label"
      :required="required" />
    <input
      v-model="modelValue"
      v-bind="$attrs"
      class="h-10 w-full border px-2 py-1 outline-none required:border-solid"
      :class="error ? 'border-red-5' : 'border-gray-3 focus:ring-primary focus:ring-1'">
    <ErrorMessage
      v-if="!hideError"
      :message="error" />
  </div>
</template>

<script setup lang="ts">
type Props = {
  error?: string;
  hideAsterisk?: boolean;
  hideError?: boolean;
  icon?: string;
  label?: string;
  required?: boolean;
};

defineProps<Props>();

const modelValue = defineModel<number | string>();
</script>
